import { IMenuPermissions } from '../../../../constants';
import { GetCurrentUserClientConfigurationDto } from '../../app/model';
import { IObjectWithEntityTranslations } from '../../../shared/service/entity-translator/entity-translator.model';
import { TabRowInterface } from '../../../shared/component/side-config-bar/side-config-bar.model';
import { ActivityCRUDInterface, LineCRUDInterface } from '../../../shared/component/filter/filter.class';
import { ILineCamera } from '../../../shared/component/camera-view-modal/camera-view-modal.model';
import {
  EWidgetType,
  WidgetConfigurationInterface
} from '../../../shared/component/page-configuration/page-configuration.model';
import {
  IColumnSortOrderConfiguration,
  IDropdownOptions,
  ITableHeaderGroupItem,
  IWidgetConfiguration,
  TGeneralConfigurationSection,
} from '../../../shared/component/general-configuration/general-configuration.model';

export interface ILineViewEnhanced {
  downTime: number;
  idleTime: number;
  runTime: number;
  orderBy: string;
  runTimeAndDownTimeOee: string;
  totalNumberOfUsers: number | null;
  totalNumberOfAssets: number | null;
  orderDesc: boolean;
  rows: ILinesReportLineExtended[];
  sitesFilter: number[];
  linesFilter: number[];
  isSitesFilterLoading: boolean;
  isLinesFilterLoading: boolean;
  isTableColumnFilterLoading: boolean;
  isSitesFilterLoaded: boolean;
  isLinesFilterLoaded: boolean;
  isTableColumnFilterLoaded: boolean;
  selectedSiteFilters: number[];
  selectedLineFilters: number[];
  filterDataGenerated: boolean;
  isSiteFilterDataGenerated: boolean;
  isLineFilterDataGenerated: boolean;
  fileDownloadStatus: boolean;
  tableData: any[];
  tableColumns: IObjectConfiguration[];
  widgets: IObjectConfiguration[];
  isTableDataLoading: boolean;
  isTableDataLoaded: boolean;
  isLineListVisible: boolean;
  isCardDataLoading: boolean;
  isCardDataLoaded: boolean;
  isWidgetsLoaded: boolean;
  isWidgetsGenerated: boolean;
  isFiltersAndSettingSaving: boolean;
  calculatedOeeDate: string;
  generatedWidgets: WidgetConfigurationInterface<EWidgetType.KPI_CARD>[];
  tableSettings: TabRowInterface[];
  lineCountOfSite: number;
  lineCountStatus: boolean;
  isActivityFilterDataGenerated: boolean;
  activitiesFilter: number[];
  selectedActivityFilters: number[];
  isActivityFilterLoading: boolean;
  isActivityFilterLoaded: boolean;
  generalConfiguration: TGeneralConfigurationSection[];
  sortOrders: IColumnSortOrderConfiguration[];
}

export interface ISite {
  statusDescription: string;
  id: number;
  name: string;
  contactPerson: string;
  email: string;
  address: string;
  location: string;
  url: string;
  tel: string;
  statusId: string;
  description: string;
  web: string;
  checked: boolean;
  selected: boolean;
  lines?: ILine[];
  menuPermissions?: IMenuPermissions;
  configuration?: GetCurrentUserClientConfigurationDto;
}

export interface ILine {
  statusDescription: string;
  siteName: string;
  id: number;
  title: string;
  siteId: number;
  statusId: string;
  description: string;
  lineType: string;
  standardSpeed: string;
  createdBy: string;
  createdAt: string;
  changedBy: string;
  changedAt: string;
  activityIds: string;
  timer: string;
  lineTypeId: string;
  activeUser: string;
  laborHourMonday: string;
  laborHourTuesday: string;
  laborHourWednesday: string;
  laborHourThursday: string;
  laborHourFriday: string;
  laborHourSaturday: string;
  laborHourSunday: string;
  selected: boolean;
  lineTypeName: {
    id: number;
    lineType: string;
  };
}

export interface ILinesReport {
  todayOEE: string | null;
  downTime: number;
  runTime: number;
  idleTime: number;
  calculatedOeeDate: string | null;
  totalNumberOfUsers: number | null;
  totalNumberOfAssets: number | null;
  lines?: ILinesReportLine[];
}

export interface ILinesReportLine {
  PostRunDuration: number | string;
  PreRunDuration: number | string;
  RunDuration: number | string;
  activityDuration: number | string;
  activityId: number;
  activityName: string;
  activityStartTime: string;
  activityType: string;
  activityTypeOrder: number;
  departmentName: string;
  id: number;
  lineName: string;
  linePhases: string;
  lineState: string;
  operatorName: string;
  phaseId: number | string;
  phaseName: string;
  postRunActivityName: string;
  preRunActivityName: string;
  productDescription: string;
  productId: string;
  siteId: number;
  siteName: string;
  statusId: number;
  taskIssueName: string;
  workOrderName: string;
  workOrderPhase: string;
  workOrderPhaseDuration: number | string;
  workOrderPhaseStart: string;
  todayOEE?: string | null;
  performance?: string;
  availability?: string;
  quality?: string;
  processOrder: string;
  job: string;
  jobId: number;
  decimalScaleLimit?: number;
  taskTitle?: string;
  oeeTrend?: IOeeTrendData[];
  lastSevenDaysOee?: ICachedDailyOee[];
}

export interface ILinesReportLineExtended extends IObjectWithEntityTranslations {
  PostRunDuration: string;
  PreRunDuration: string;
  RunDuration: string;
  activityDuration: number | string;
  activityId: number;
  activityName: string;
  activityStartTime: string;
  activityType: string;
  activityTypeOrder: number;
  averageTarget: string;
  departmentName: string;
  id: number;
  lineName: string;
  linePhases: string;
  lineState: string;
  operatorName: string;
  phaseId: number | string;
  phaseName: string;
  postRunActivityName: string;
  preRunActivityName: string;
  productDescription: string;
  productId: string;
  siteId: number;
  siteName: string;
  statusId: number;
  taskIssueName: string;
  taskTitle: string;
  workOrderName: string;
  workOrderPhase: string;
  workOrderPhaseDuration: number | string;
  workOrderPhaseStart: string;
  todayOEE?: string | null;
  performance?: string;
  availability?: string;
  quality?: string;
  workOrderGoodCount: number | string;
  averageSpeed: number | string;
  workOrderDuration: number | string;
  runTimeAverageSpeed: number | string;
  scrapQuantity: number | string;
  workOrderQuantityOrdered: number | string;
  targetDuration: number | string;
  expectedSpeed: string;
  numberOfCheckInUsers?: number;
  nameOfCheckInUsers?: string;
  numberOfCheckInAssets?: number;
  nameOfCheckInAssets?: string;
  checkIns?: IOngoingCheckIn[];
  scheduledPreRunDuration: string;
  scheduledRunDuration: string;
  scheduledPostRunDuration: string;
  processOrder: string;
  job: string;
  jobId: number;
  decimalScaleLimit?: number;
  nameOfCheckInMaintenanceUsers?: string;
  isCameraViewModule: boolean;
  timezone: string;
  cameraList?: ILineCamera[];
  equipmentId: number;
  equipmentName: string;
}

export interface ILineViewRowsFormatted extends ILinesReportLineExtended {
  preRunVersusTargetPercentage: string;
  preRunVersusTargetPercentageCapped: number;
  preRunVersusTargetNumerical: string;
  preRunVersusTargetNumericalValue: string;
  preRunVersusTargetNumericalActual: string;
  preRunVersusTargetNumericalScheduled: string;
  runVersusTargetPercentage: string;
  runVersusTargetPercentageCapped: number;
  runVersusTargetNumerical: string;
  runVersusTargetNumericalValue: string;
  runVersusTargetNumericalActual: string;
  runVersusTargetNumericalScheduled: string;
  postRunVersusTargetPercentage: string;
  postRunVersusTargetPercentageCapped: number;
  postRunVersusTargetNumerical: string;
  postRunVersusTargetNumericalValue: string;
  postRunVersusTargetNumericalActual: string;
  postRunVersusTargetNumericalScheduled: string;
  operators?: string;
  supervisor?: string;
  lineLeader?: string;
  admin?: string;
  workOrderPhaseOriginal?: string;
  oeeTrend?: IOeeTrendData[] | string;
  lastSevenDaysOee?: ICachedDailyOee[] | string;
}

export interface IFilterData {
  sites: number[];
  lines: number[];
  activities: number[];
  table: IObjectConfiguration[];
  widgets: IObjectConfiguration[];
}

export interface ISiteLineActivityFilterData {
  siteData: number[];
  lineData: number[];
  activityData: number[];
}

export interface IObjectConfiguration {
  name: string;
  selected: boolean;
}

export interface ITableColumn extends IObjectConfiguration {
  disabled?: boolean;
  title: string;
}

export interface IWidgetData extends TabRowInterface {
  icon: string;
  cardColor: string;
  cardValue: string;
  displayPercentage?: boolean;
}

export interface ISetFilterData {
  siteIds: number[];
  lineIds: number[];
  activityIds: number[];
  setAsDefault: boolean;
}

export interface IFetchDataErrorMessage {
  payload: { errorMessage: string };
}

export interface IOngoingCheckIn {
  id: number;
  lineId: number;
  sourceObjectId: number;
  sourceTypeId: number;
  name: string;
  shortName: string;
  isMaintenanceUser: number;
  levelId?: number;
  avatarPath?: string;
  avatar?: string;
  isAvatarLoading?: boolean;
  nameIcon?: string;
}

export interface ILineViewWOSummaryFilter {
  siteIds: number[];
  lineIds: LineCRUDInterface[];
  activityIds: ActivityCRUDInterface[];
}

export interface ICheckInIcons {
  users: string[];
  assets: string[];
  maintenanceUsers?: string[];
  operators?: IOngoingCheckIn[];
  supervisor?: IOngoingCheckIn[];
  lineLeader?: IOngoingCheckIn[];
  admin?: IOngoingCheckIn[];
}

export interface ICheckInColumnValues {
  users: string;
  assets: string;
  maintenanceUsers?: string;
  operators?: string;
  supervisor?: string;
  lineLeader?: string;
  admin?: string;
}

export interface ILineViewPhaseActualUnformatted {
  preRunDuration: string;
  runDuration: string;
  postRunDuration: string;
}

export interface IWidgetColumnDetails {
  widgetCount: number;
  perRow: number;
}

export interface IColumnNameAndTitle {
  name: string;
  title: string;
}

export interface IGridCurrentSort {
  isAscending: boolean;
  field: string;
}

export enum LineStateOrderEnum {
  runTime = 0,
  downTime = 1,
  downTimePlanned = 2,
  idleTime = 3,
}

export interface IFilterSettings {
  filterLineId?: number[];
  filterSiteId?: number[];
  filterActivityId?: number[];
  table: string[];
  widgets: string[];
}

export interface IFilter {
  selectedLines: number[];
  selectedSites: number[];
  selectedActivities: number[];
  selectedColumns: any[];
  selectedWidgets: any[];
}

export interface ISelectedWidget {
  name: string;
  selected: boolean;
}

export type TDisplaySetting =
  | 'actual'
  | 'actualTarget'
  | 'progress'
  | 'text'
  | 'textAndIcon'
  | 'icon'
  | 'textAndImage'
  | 'image'
  | 'actualScores'
  | 'lineChart'
  | 'progressPercent'
  | 'chart';

export enum EUserLevelLookup {
  'admin' = 1,
  'supervisor' = 4,
  'lineLeader' = 16,
  'operators' = 32,
}

export enum ELineViewEnhancedResponseMode {
  ALL = 'all',
  CARD = 'card',
  SUMMARY = 'summary',
}

export interface IUserConfigurationFromGeneralConfiguration {
  headers: ITableHeaderGroupItem[];
  widgets: WidgetConfigurationInterface<EWidgetType.KPI_CARD>[];
  sortOrders: IColumnSortOrderConfiguration[];
  highlightColumns: IDropdownOptions[];
}

export enum EProgressBarColors {
  WHITE = '#FFFFFF',
  GRAY = '#9B9BA1',
  BLACK = '#000000',
  RED = '#FF726B',
  YELLOW = '#FFD600',
  GREEN = '#6FC764',
}

export interface IOeeTrendData {
  oee: string;
  startDate: string;
}

export interface ICachedDailyOee {
  date: string;
  overallOee: string;
  availability: string;
  performance: string;
  quality: string;
  goodCount: string;
  scrapCount: string;
  runTimeDuration: string;
  downTimeDuration: string;
  idleDuration: string;
}

export enum ECheckInWidth {
  TEXT_AND_IMAGE = 60,
  IMAGE = 36,
}
