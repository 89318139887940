import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, take, takeUntil } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import * as ObjectActions from './work-order-schedule.actions';
import { of, Subject } from 'rxjs';
import * as AppActions from '../../store/app/actions';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../oee.reducer';
import { WorkOrderScheduleService } from '../../shared/service/work-order-schedule/work-order-schedule.service';
import {
  BulkEditResponseInterface,
  FormattedWorkOrdersResponseInterface,
  GetWorkOrderResponseInterface,
  ProductionStagesResponseInterface,
  ProductsInterface,
  ProductsResponseInterface,
  WorkOrderEditCreateResponseInterface,
  WorkOrderInterface,
  WorkOrderResponseInterface,
  RecalculateResponseInterface,
  WorkOrderScheduleSite,
} from './work-order-schedule.model';
import * as _ from 'lodash';
import { WorkOrderDeleteResponseInterface } from '../../shared/service/work-order-schedule/work-order-schedule.model';
import { WorkOrderScheduleSaveOneDtoInterface } from '../../view/work-orders-new-design/work-orders-new-design.model';
import { ErrorMessageService } from '../../shared/service/error-message.service';
import { ExcelHelperService } from '../../shared/service/excel/excel-helper.service';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../shared/model/interface/crud-response-interface.model';
import {
  FieldTypes,
  ICustomEndpointAdvancedFilterParams,
  IFilterOutput,
  OperatorTypes,
  QueryTypes,
  RawSqlOperators,
  TargetEndpoints,
} from '../../shared/component/filter/advanced-filter/advanced-filter.model';
import { AdvancedFilterService } from '../../shared/component/filter/advanced-filter/advanced-filter.service';
import { DateRangeTypes } from '../../shared/component/filter/filterable-objects.class';
import { mysqlTimestampFormat } from '../../shared/helper/date';
import * as moment from 'moment';
import { LineCRUDResponseInterface, SiteCRUDResponseInterface } from '../../shared/service/filter/service.class';
import { IJob } from '../settings/jobs/jobs.model';
import { JobsService } from '../settings/jobs/jobs.service';
import { EntityTranslatorService } from '../../shared/service/entity-translator/entity-translator.service';
import { User } from '../user/model';
import { emptyAction } from '../../../constants';
import { HelperService } from '../../shared/service/helper.service';
import { ProductTransitionMatrixService } from '../settings/product-transition-matrix/product-transition-matrix.service';
import { IGetProductTransitionMatrix } from '../settings/product-transition-matrix/product-transition-matrix.model';
import { HTTPHelperService } from '../../shared/service/http/http.helper.service';
import { SiteService } from '../../shared/service/filter/site.service';

@Injectable()
export class WorkOrderScheduleEffects {
  private userLanguage$: string = '';
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();
  private locale$: string;

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly workOrderScheduleService: WorkOrderScheduleService,
    private readonly errorMessageService: ErrorMessageService,
    private readonly excelHelperService: ExcelHelperService,
    private readonly advancedFilterService: AdvancedFilterService,
    private readonly jobService: JobsService,
    private readonly entityTranslation: EntityTranslatorService,
    private readonly productTransitionMatrixService: ProductTransitionMatrixService,
    private readonly httpHelper: HTTPHelperService,
    private readonly siteService: SiteService,
  ) {
    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((state: User) => {
        if (state.isUserLoaded) {
          this.userLanguage$ = state.language;
          this.locale$ = state.locale;
        }
        this.destroySubject.next(true);
        this.destroySubject.complete();
      });
  }

  getWorkOrderScheduleList = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_WORK_ORDER_SCHEDULE_LIST),
      switchMap((objectData: ObjectActions.GetWorkOrderScheduleList) => {
        if (objectData.newDesign) {
          return this.workOrderScheduleService
            .getCustomWorkOrdersNewDesign(this.getWorkOrderParams(objectData, false))
            .pipe(
              switchMap((response: WorkOrderResponseInterface) => {
                response.data.rows.forEach((workOrder: WorkOrderInterface) => {
                  this.entityTranslation.translate(workOrder);
                });

                return of(
                  new ObjectActions.GetWorkOrderScheduleListCompleted(
                    this.workOrderScheduleService.formatResponse(response),
                  ),
                );
              }),
              catchError((error) => {
                return of(new ObjectActions.FetchError(error));
              }),
            );
        }

        return this.workOrderScheduleService.getCustomWorkOrders(objectData.filters, objectData.query).pipe(
          switchMap((response: FormattedWorkOrdersResponseInterface) => {
            return of(new ObjectActions.GetWorkOrderScheduleListCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  private addDueDateFilterToParams(
    objectData: ObjectActions.GetWorkOrderScheduleList | ObjectActions.DownloadExcel,
    weekStartDay: number,
  ): { filterStartDate?: string; filterEndDate?: string } {
    if (!('dynamicDate' in objectData.filters) || !objectData.filters.dynamicDate.length) {
      return {};
    }

    this.store
      .select('user')
      .pipe(take(1))
      .subscribe((state: User) => {
        if (state.isUserLoaded) {
          this.locale$ = state.locale;
        }
      });

    if (this.locale$) {
      HelperService.setCustomMomentLocaleDayOfWeek(this.locale$, weekStartDay);
    }

    const now = moment().tz(objectData.dynamicDateFilterParameters.timezone);

    switch (objectData.filters.dynamicDate[0]) {
      case DateRangeTypes.Today:
        return {
          filterStartDate: now.clone().startOf('d').format(mysqlTimestampFormat),
          filterEndDate: now.clone().endOf('d').format(mysqlTimestampFormat),
        };
      case DateRangeTypes.Yesterday:
        return {
          filterStartDate: now.clone().startOf('d').subtract(1, 'd').format(mysqlTimestampFormat),
          filterEndDate: now.clone().endOf('d').subtract(1, 'd').format(mysqlTimestampFormat),
        };
      case DateRangeTypes.ThisWeek:
        return {
          filterStartDate: now
            .clone()
            .locale(objectData.dynamicDateFilterParameters.locale)
            .startOf('w')
            .format(mysqlTimestampFormat),
          filterEndDate: now
            .clone()
            .locale(objectData.dynamicDateFilterParameters.locale)
            .endOf('w')
            .format(mysqlTimestampFormat),
        };
      case DateRangeTypes.NextSevenDays:
        return {
          filterStartDate: now.clone().startOf('d').format(mysqlTimestampFormat),
          filterEndDate: now.clone().add(6, 'd').endOf('d').format(mysqlTimestampFormat),
        };
      case DateRangeTypes.LastWeek:
        return {
          filterStartDate: now
            .clone()
            .locale(objectData.dynamicDateFilterParameters.locale)
            .startOf('w')
            .subtract(1, 'w')
            .format(mysqlTimestampFormat),
          filterEndDate: now
            .clone()
            .locale(objectData.dynamicDateFilterParameters.locale)
            .endOf('w')
            .subtract(1, 'w')
            .format(mysqlTimestampFormat),
        };
      case DateRangeTypes.ThisMonth:
        return {
          filterStartDate: now.clone().startOf('month').format(mysqlTimestampFormat),
          filterEndDate: now.clone().endOf('month').format(mysqlTimestampFormat),
        };
      case DateRangeTypes.NextThirtyDays:
        return {
          filterStartDate: now.clone().startOf('d').format(mysqlTimestampFormat),
          filterEndDate: now.clone().add(29, 'd').endOf('d').format(mysqlTimestampFormat),
        };
      case DateRangeTypes.LastMonth:
        return {
          filterStartDate: now.clone().startOf('month').subtract(1, 'month').format(mysqlTimestampFormat),
          filterEndDate: now.clone().endOf('month').subtract(1, 'month').format(mysqlTimestampFormat),
        };
      case DateRangeTypes.LastThreeMonths:
        return {
          filterStartDate: now.clone().startOf('month').subtract(3, 'month').format(mysqlTimestampFormat),
          filterEndDate: now.clone().endOf('month').subtract(1, 'month').format(mysqlTimestampFormat),
        };
      case DateRangeTypes.ThisYear:
        return {
          filterStartDate: now.clone().startOf('year').format(mysqlTimestampFormat),
          filterEndDate: now.clone().endOf('year').format(mysqlTimestampFormat),
        };
      case DateRangeTypes.LastYear:
        return {
          filterStartDate: now.clone().startOf('year').subtract(1, 'year').format(mysqlTimestampFormat),
          filterEndDate: now.clone().endOf('year').subtract(1, 'year').format(mysqlTimestampFormat),
        };
    }
    HelperService.setDefaultMomentLocaleDayOfWeek(this.locale$);
    return {};
  }

  private getWorkOrderScheduleQueryParams(
    payload: ObjectActions.GetWorkOrderScheduleList | ObjectActions.DownloadExcel,
  ): ICustomEndpointAdvancedFilterParams {
    const searchParams: { $and: any[] } = {
      $and: [],
    };

    if (payload.filters.advancedFilter) {
      const advancedFilter: IFilterOutput[] = payload.filters.advancedFilter.filters;

      for (const filter of advancedFilter) {
        if (filter.queryType !== QueryTypes.withinAdvancedFilterParams) continue;

        switch (filter.type) {
          case FieldTypes.predefined:
            searchParams.$and.push(
              this.advancedFilterService.generateQuery(
                filter.path,
                filter.type,
                filter.operator.name,
                filter.operator.type,
                payload.filters.advancedFilter.target,
                _.get(filter.value, `[0][${filter.searchBy}]`, ''),
              ),
            );
            break;

          default:
            searchParams.$and.push(
              this.advancedFilterService.generateQuery(
                filter.path,
                filter.type,
                filter.operator.name,
                filter.operator.type,
                payload.filters.advancedFilter.target,
                filter.value,
              ),
            );
            break;
        }
      }
    }

    return searchParams;
  }

  private getWorkOrderParams(
    objectData: ObjectActions.GetWorkOrderScheduleList | ObjectActions.DownloadExcel,
    isDownloadExcel: boolean,
  ): HttpParams {
    let params: HttpParams = new HttpParams();
    const siteId: number = objectData.filters.siteId.map((site) => site?.id)[0];
    const weekStartDay: number = objectData.filters.siteId.map((site) => site?.weekStartDay)[0];

    params = params.set('siteId', String(siteId));

    if (!isDownloadExcel) {
      params = params
        .append('offset', String(objectData.query.page))
        .append('limit', String(objectData.query.pageSize || 1000));
    } else {
      if (!(objectData instanceof ObjectActions.GetWorkOrderScheduleList)) {
        params = params.append('offset', objectData.downloadOffsetValue).append('limit', String(5000));
      }
    }

    if (objectData.query.order) {
      params = params
        .set('order', `${objectData.query.order}`)
        .set('orderType', `${objectData.query.orderType.toUpperCase()}`);
    }

    if (objectData.filters.lineIds && objectData.filters.lineIds !== -1) {
      params = params.set('lineIds', String(objectData.filters.lineIds));
    }

    if (objectData.filters.lastLineIds && objectData.filters.lastLineIds !== -1) {
      params = params.set('lastLineIds', String(objectData.filters.lastLineIds));
    }

    if (objectData.filters.jobIds && objectData.filters.jobIds !== -1) {
      params = params.set('jobIds', String(objectData.filters.jobIds));
    }

    if (objectData.query.search !== undefined && siteId !== -1 && siteId !== undefined) {
      params = params.set('searchType', String('woNumber'));
      params = params.set('search', String(objectData.query.search));
    }

    let searchObject: ICustomEndpointAdvancedFilterParams = { $and: [] };

    if (!_.isEmpty(objectData.filters.advancedFilter)) {
      const queryStringFilters: IFilterOutput[] = objectData.filters.advancedFilter.filters.filter(
        (filter: IFilterOutput) => {
          return filter.queryType === QueryTypes.withinRequestQueryString;
        },
      );

      queryStringFilters.forEach((filter: IFilterOutput) => {
        if (filter.type === FieldTypes.predefined) {
          params = params.set(filter.path, _.get(filter.value, `[0][${filter.searchBy}]`, ''));
        } else {
          params = params.set(filter.path, filter.value);
        }
      });

      const advancedFilterPage: string =
        objectData.filters.advancedFilter.page === 'work-orders-dashboard-mode'
          ? 'work-orders'
          : objectData.filters.advancedFilter.page;
      params = params.set('advancedFilterPage', advancedFilterPage);
      searchObject = this.getWorkOrderScheduleQueryParams(objectData);
    }

    if (objectData.dynamicDateFilterParameters.isDashboardMode) {
      const dynamicDateFilter: string = objectData.dynamicDateFilterParameters.dynamicDateFilter;
      _.remove(searchObject.$and, { path: dynamicDateFilter });
      const { filterStartDate, filterEndDate } = this.addDueDateFilterToParams(objectData, weekStartDay);
      searchObject.$and.push({
        path: dynamicDateFilter,
        fieldType: FieldTypes.dateTime,
        operator: RawSqlOperators.BETWEEN,
        operatorType: OperatorTypes.range,
        value: { startDate: filterStartDate, endDate: filterEndDate },
      });
    }

    if (searchObject.$and.length) {
      params = params.set(
        this.advancedFilterService.getRequestQueryParameterName(
          objectData.query.advancedFilter?.target ?? TargetEndpoints.Custom,
        ),
        JSON.stringify(searchObject),
      );
    }

    return params;
  }

  getWorkOrderForWorkOrderForm = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_WORK_ORDER),
      switchMap((objectData: ObjectActions.GetWorkOrder) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const params = new HttpParams()
          .append('join', 'workOrderSplitNumber||id')
          .append('join', 'workOrderSplitNumber.parentWorkOrder||woNumber')
          .append('join', 'job||jobName')
          .append('join', 'productVersionInfo')
          .append('join', 'department||id,lineType');

        return this.workOrderScheduleService.getWorkOrder(objectData.workOrderId, params).pipe(
          switchMap((response: GetWorkOrderResponseInterface) => {
            this.entityTranslation.translate(response.data);

            return of(
              new ObjectActions.GetWorkOrderCompleted(response),
              new ObjectActions.GetProductionStages(),
              new ObjectActions.GetProducts(response.data.siteId, null, response.data.productId),
              new ObjectActions.GetJobs(response.data.siteId, null, response.data.jobId),
              new AppActions.HideLoader(),
            );
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  getSites = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_SITES),
      switchMap(() => {
        this.store.dispatch(new AppActions.ShowLoader());
        const httpParams = this.httpHelper.insertGenericCrudRequestParameters({
          join: ['linePaths||name', 'linePaths.floorPlan||name'],
          limit: 1000,
        });
        return this.siteService.getSites(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<WorkOrderScheduleSite>) => {
            return of(new ObjectActions.GetSitesCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  getLines = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_LINES),
      switchMap((objectData: ObjectActions.GetLines) => {
        this.store.dispatch(new AppActions.ShowLoader());
        let search;
        let httpParams = new HttpParams().set('limit', '1000');
        if (objectData.siteId) {
          search = {
            statusId: 1,
            siteId: objectData.siteId,
          };
          httpParams = httpParams.set('s', JSON.stringify(search));
        }

        httpParams = httpParams.set('join', 'lineTypeName||id,lineType');

        return this.workOrderScheduleService.getLines(httpParams).pipe(
          switchMap((response: LineCRUDResponseInterface) => {
            return of(new ObjectActions.GetLinesCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  getProducts = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_PRODUCTS),
      switchMap((objectData: ObjectActions.GetProducts) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.workOrderScheduleService
          .getProducts(objectData.siteId, objectData.searchText, objectData.productId)
          .pipe(
            switchMap((response: ProductsResponseInterface) => {
              response.data.forEach((product: ProductsInterface) => this.entityTranslation.translate(product));
              return of(new ObjectActions.GetProductsCompleted(response), new AppActions.HideLoader());
            }),
            catchError((error) => {
              return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
            }),
          );
      }),
    ),
  );

  getProductionStages = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_PRODUCTION_STAGES),
      switchMap(() => {
        this.store.dispatch(new AppActions.ShowLoader());
        const httpParams = new HttpParams().set('limit', '1000');

        return this.workOrderScheduleService.getProductionStages(httpParams).pipe(
          switchMap((response: ProductionStagesResponseInterface) => {
            return of(new ObjectActions.GetProductionStagesCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  saveWorkOrderAddForm = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SAVE_WORK_ORDER_ADD_FORM),
      switchMap((objectData: ObjectActions.SaveWorkOrderAddForm) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.workOrderScheduleService.saveWorkOrderAddForm(objectData.workOrderAddForm).pipe(
          switchMap((response: WorkOrderEditCreateResponseInterface) => {
            return of(new ObjectActions.SaveWorkOrderAddFormCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  deleteWorkOrders = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DELETE_WORK_ORDER),
      switchMap((objectData: ObjectActions.DeleteWorkOrder) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.workOrderScheduleService.deleteWorkOrder(objectData.payload).pipe(
          switchMap((response: WorkOrderDeleteResponseInterface) => {
            if (response.data === undefined || Object.keys(response.data).length === 0) {
              _.set(response, 'data', [
                {
                  success: response.success,
                  message: response.message,
                },
              ]);
            }

            this.errorMessageService.getTranslatedErrorMessage(response.data);
            return of(new ObjectActions.DeleteWorkOrderCompleted(objectData.payload, response));
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  editWorkOrders = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EDIT_WORK_ORDER),
      switchMap((objectData: ObjectActions.EditWorkOrder) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.workOrderScheduleService.editWorkOrder(objectData.payload, objectData.workOrderId).pipe(
          switchMap((response: WorkOrderEditCreateResponseInterface) => {
            return of(new ObjectActions.EditWorkOrderCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  bulkEditWorkOrders = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.BULK_EDIT_WORK_ORDER),
      switchMap((objectData: ObjectActions.BulkEditWorkOrder) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return objectData.newDesign
          ? this.workOrderScheduleService.newDesignBulkEditWorkOrder(objectData.payload).pipe(
              switchMap((response: BulkEditResponseInterface) => {
                this.errorMessageService.getTranslatedErrorMessage(response.data);
                return of(
                  new ObjectActions.BulkEditWorkOrderCompleted(objectData.payload, response),
                  new AppActions.HideLoader(),
                );
              }),
              catchError((error) => {
                return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
              }),
            )
          : this.workOrderScheduleService.bulkEditWorkOrder(objectData.payload).pipe(
              switchMap((response: BulkEditResponseInterface) => {
                this.errorMessageService.getTranslatedErrorMessage(response.data);
                return of(
                  new ObjectActions.BulkEditWorkOrderCompleted(objectData.payload, response),
                  new AppActions.HideLoader(),
                );
              }),
              catchError((error) => {
                return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
              }),
            );
      }),
    ),
  );

  recalculateWorkOrders = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.RECALCULATE_WORK_ORDER),
      switchMap((objectData: ObjectActions.RecalculateWorkOrder) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.workOrderScheduleService.recalculateWorkOrder(objectData.payload).pipe(
          switchMap((response: RecalculateResponseInterface) => {
            return of(
              new ObjectActions.RecalculateWorkOrderCompleted(objectData.payload, response),
              new AppActions.HideLoader(),
            );
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  downloadExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DOWNLOAD_EXCEL),
      switchMap((objectData: ObjectActions.DownloadExcel) => {
        this.workOrderScheduleService.downloadExcel(
          objectData.withData,
          objectData.siteId,
          this.getWorkOrderParams(objectData, true),
        );
        return emptyAction;
      }),
    ),
  );

  uploadExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.UPLOAD_EXCEL),
      switchMap((objectData: ObjectActions.UploadExcel) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.workOrderScheduleService.uploadExcel(objectData.workOrders).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            this.errorMessageService.getTranslatedErrorMessage(response.data);
            const mergedArray =
              this.excelHelperService.mergeBulkResponseWithRequestData<WorkOrderScheduleSaveOneDtoInterface>(
                response,
                objectData.workOrders.workOrders,
              );
            return of(new ObjectActions.UploadExcelCompleted(mergedArray), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  getJobs = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_JOBS),
      switchMap((objectData: ObjectActions.GetJobs) => {
        this.store.dispatch(new AppActions.ShowLoader());
        let httpParams = new HttpParams().set('limit', '50');
        const filter: any = {
          $and: [{ siteId: { $eq: String(objectData.siteId) } }],
        };

        if (objectData.searchText !== null) {
          filter.$and.push({ jobName: { $cont: objectData.searchText } });
        }

        httpParams = httpParams.set('s', JSON.stringify(filter));

        if (objectData.jobId !== null) {
          httpParams = httpParams.set('sort', `id=${objectData.jobId},DESC`);
        }

        return this.jobService.getJobs(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<IJob>) => {
            return of(new ObjectActions.GetJobsCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  createJob = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.CREATE_NEW_JOB),
      switchMap((objectData: ObjectActions.CreateNewJob) => {
        return this.jobService.createJob(objectData.job).pipe(
          switchMap((response: BaseOneResponseInterface<IJob>) => {
            return of(new ObjectActions.CreateNewJobCompleted(response.data), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getProductTransitionMatrix = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_PRODUCT_TRANSITION_MATRIX),
      switchMap((objectData: ObjectActions.GetProductTransitionMatrix) => {
        return this.productTransitionMatrixService.getProductTransitionMatrixOfWorkOrder(objectData.payload).pipe(
          switchMap((response: BaseOneResponseInterface<IGetProductTransitionMatrix>) => {
            return of(
              new ObjectActions.GetProductTransitionMatrixCompleted(response.data),
              new AppActions.HideLoader(),
            );
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );
}
