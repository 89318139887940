import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { LineCRUDInterface } from 'src/app/shared/component/filter/filter.class';
import { showApplyChangesModal } from 'src/app/shared/helper/modal.utilities';
import {
  ChangeActivityRequestInterface,
  IWorkOrderCloseTableData,
  OEEBarStyles,
  WorkOrderCloseDataInterface,
  WorkOrderCloseFormDataResponseInterface,
  WorkOrderCloseResponseInterface,
  WorkOrderCloseStatisticsInterface,
} from '../../../../store/home/home.model';
import { OeeProgressModel } from '../../models/oee-progress.model';
import { ManualSensorModel } from '../../models/manual-sensor.model';
import * as AppActions from '../../../../store/app/actions';
import { shareReplay, take } from 'rxjs/operators';
import { WorkOrderFinalizeStateInterface } from '../../../../store/work-order-finalize/work-order-finalize.model';
import * as HomeActions from '../../../../store/home/home.actions';
import { ofType } from '@ngrx/effects';
import { Line } from '../../../../store/line/model';
import { ActionsSubject, Store } from '@ngrx/store';
import { Observable, startWith, Subscription } from 'rxjs';
import { HelperService } from '../../../../shared/service/helper.service';
import { TranslateService } from '@ngx-translate/core';
import { OeeAppState } from '../../../../store/oee.reducer';
import { InputLimit } from '../../../../shared/model/enum/input-limit';
import { WoFinalQuantityFormDataService } from '../../../../store/work-order-finalize/wo-final-quantity-form-data.service';
import { WizardComponent } from 'angular-archwizard';
import {
  WorkOrderCloseFeedbackInterface,
  WorkOrderCloseFeedbackResponseInterface,
} from '../../../../store/work-order/work-order.model';
import { NgModel } from '@angular/forms';
import { DatatableHeaderInterface } from '../../../../shared/component/datatable/datatable.model';
import { HomeActivityTreeChartService } from '../../../../store/home/home-activity-tree-chart.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IManualCountConfiguration } from '../work-order.model';
import {
  CountModalType,
  IDistributionData,
  IncrementDistributionMethod,
} from '../work-order-manual-count/work-order-manual-count.model';
import * as ManualCountActions from '../../../../store/work-order-manual-count/actions';
import { DecimalHelper } from '../../../../shared/helper/decimal/decimal-helper';
import { OnDestroyDecorator } from '../../../../shared/decorator/on-destroy-decorator';
import { ECountMode } from '../../../../store/work-order-manual-count/model';
import * as _ from 'lodash';
import { EDistributionMethodOfOrderedQuantity } from '../../../../store/settings/departments-lines-stations/lines/lines.model';
import { IWorkOrderActivityHistoryModalData } from '../../../../shared/component/work-order-activity-history/work-order-activity-history.model';
import { ECountEntryMethods, mediumModal } from '../../../../../constants';
import { IManageCountOutput } from '../../../../shared/helper/decimal/count-helper';

@OnDestroyDecorator
@Component({
  selector: 'app-batch-close',
  templateUrl: './batch-close.component.html',
  styleUrls: ['./batch-close.component.scss'],
})
export class BatchCloseComponent implements OnInit, OnDestroy {
  @Input() data: WorkOrderCloseDataInterface;
  @Output() formIsSubmitted = new EventEmitter<void>();
  @Output() modalClosed = new EventEmitter<void>();
  @ViewChild(WizardComponent, { static: false }) public wizard: WizardComponent;
  @ViewChild('actualFinalQuantityModel', { static: false }) actualFinalQuantityModel: NgModel;
  @ViewChild('actualFinalScrapModel', { static: false }) actualFinalScrapModel: NgModel;

  public distributeOrderedQuantityFlag: boolean = false;
  public finalCountDirty: boolean;
  public oldWorkOrderId: number;
  public oldWorkOrder: string;
  public oldWorkOrderTableId: number;
  public productNameOfTheWorkOrder: string;
  public oeeProgress: OeeProgressModel;
  public oeeStyles: {
    overall: string;
    a: string;
    p: string;
    q: string;
  };
  public workOrderCloseFeedbackForm: any;
  public workOrderCloseSensorData: ManualSensorModel;
  public finalQuantity: string = '0';
  public finalScrap: string = '0';
  public speedInfo: string = '';
  public formattedProductUnit: string = '';
  private workOrderFinalizeStoreSubscription: Subscription;
  private workOrderCloseFeedbackSubscription: Subscription;
  private homeStoreSubscription: Subscription;
  InputLimit = InputLimit;
  private changeActivityRequest: ChangeActivityRequestInterface;
  private lineId$: number;
  private siteId$: number;
  public userScaleLimit$: number;
  public siteDecimalScaleLimit$: number;
  private readonly subscriptions: Subscription[] = [];
  public readonly tableHeaders: DatatableHeaderInterface[] = this.homeActivityTreeChartService.getTableHeaders(
    this.translate,
  );
  public workOrderCloseTableData: IWorkOrderCloseTableData[] = [];
  public quantityModalRef: NgbModalRef;
  public manageCountModalRef: NgbModalRef;
  public manualCountConfiguration: IManualCountConfiguration;
  private manualCountDistributionRequest: IDistributionData;
  public readonly ECountMode = ECountMode;
  public readonly countModalType = CountModalType;
  private goodCountForDistribution: string = '0';
  private scrapCountForDistribution: string = '0';
  public distributeMethodTranslation: string = '';
  public isWorkOrderMissing: boolean = false;
  public workOrderActivityHistoryData: IWorkOrderActivityHistoryModalData;
  public manageCountModalCountEntryMethod$ = new Observable<ECountEntryMethods.REDUCIBLE_CUMULATIVE>().pipe(
    startWith(ECountEntryMethods.REDUCIBLE_CUMULATIVE as const),
    shareReplay(1),
  );

  constructor(
    private readonly ngbModal: NgbModal,
    private store: Store<OeeAppState>,
    private translate: TranslateService,
    private actionsSubject: ActionsSubject,
    private homeActions: ActionsSubject,
    private woFinalQuantityService: WoFinalQuantityFormDataService,
    private homeActivityTreeChartService: HomeActivityTreeChartService,
    private readonly decimalHelper: DecimalHelper,
  ) {
    this.workOrderCloseFeedbackForm = {
      id: null,
      workOrderId: null,
      crewPerformPoint: null,
      goodThings: '',
      needToBeImprove: '',
      variancesExplanation: '',
    };

    this.workOrderCloseSensorData = {
      goodCount: null,
      scrapCount: null,
    };

    this.oeeProgress = {
      overall: 0,
      availability: 0,
      performance: 0,
      quality: 0,
    };

    this.oeeStyles = {
      overall: 'oee-none',
      a: 'oee-none',
      p: 'oee-none',
      q: 'oee-none',
    };
  }

  ngOnInit(): void {
    this.store
      .select('line')
      .pipe(take(1))
      .subscribe((state: Line) => {
        this.manualCountConfiguration = {
          workOrderId: Number(state.wOTableId),
          workOrder: state.workOrderId,
          start: state.activityStart,
          productId: state.productId,
          productDescription: state.productDescription,
          unitName: state.unitName,
        };
      });

    this.openBatchCloseModal(this.data);

    if (this.oldWorkOrderTableId) {
      this.store.dispatch(new HomeActions.GetWorkOrderFeedback(this.oldWorkOrderTableId));
    }

    this.homeStoreSubscription = this.store.select('homeStore').subscribe((state) => {
      if (state.getWorkOrderFeedbackLoaded) {
        this.workOrderCloseFeedbackForm = HelperService.cloneDeep(state.workOrderCloseFeedback);
      }

      this.workOrderActivityHistoryData = {
        ...this.workOrderActivityHistoryData,
        workOrderData: {
          id: _.get(state.line.workOrderCard, 'wOTableId', null),
          woNumber: _.get(state.line.workOrderCard, 'workOrderId', null),
          quantityOrdered: _.get(state.line.workOrderCard, 'workOrderQuantityOrdered', null),
          product: {
            id: _.get(state.line.workOrderCard, 'productTableId', null),
            productId: _.get(state.line.workOrderCard, 'productId', null),
            description: _.get(state.line.workOrderCard, 'productDescription', null),
          },
          lineId: this.lineId$,
        },
      };

      if (state.workOrderMissingControlLoaded) {
        this.isWorkOrderMissing = state.isWorkOrderMissing;
      }
    });

    this.workOrderCloseFeedbackSubscription = this.actionsSubject
      .pipe(
        ofType(
          HomeActions.HomeActionTypes.SaveWorkOrderFeedbackLoaded,
          HomeActions.HomeActionTypes.UpdateWorkOrderFeedbackCompleted,
        ),
      )
      .subscribe((payload: { payload: WorkOrderCloseFeedbackResponseInterface }) => {
        if (payload.payload.success) {
          this.store.dispatch(new AppActions.ShowLoader());
          this.changeActivity();
          this.modalClosed.emit();
        }
      });

    this.store
      .select('user')
      .pipe(take(1))
      .subscribe((state) => {
        this.lineId$ = state.lineId;
        this.siteId$ = state.siteId;
        this.userScaleLimit$ = state.decimalScaleLimit;
        this.siteDecimalScaleLimit$ = state.siteDecimalScaleLimit;
      });

    this.subscriptions.push(
      this.homeActions.pipe(ofType(HomeActions.HomeActionTypes.UpdateCurrentActivity)).subscribe(() => {
        this.store.dispatch(new HomeActions.ChangeActivity(...HelperService.argumentClone(this.changeActivityRequest)));
      }),
      this.actionsSubject
        .pipe(
          ofType(
            ManualCountActions.MANUAL_COUNT_SET_DISTRIBUTION_LOADED,
            ManualCountActions.SET_HOURLY_COUNT_COMPLETED,
            ManualCountActions.SET_BULK_HOURLY_COUNT_COMPLETED,
          ),
        )
        .subscribe(() => {
          this.manageCountModalRef?.close(true);
          this.store.dispatch(new HomeActions.GetQuantityFromSensor(true));
          this.setBatchModalData();
        }),
    );
  }

  public distributeOrderedQuantity(): void {
    this.store.dispatch(
      new ManualCountActions.ManualCountSetDistributionLoading({
        doApproveOngoingShiftHour: true,
        incrementDistributionMethod: IncrementDistributionMethod.runTimesIfAvailable,
        goodCount: this.goodCountForDistribution,
        scrapCount: this.scrapCountForDistribution,
        workOrderId: this.oldWorkOrderId,
      }),
    );
  }

  openBatchCloseModal(data: WorkOrderCloseDataInterface): void {
    this.checkAndSetDistributeOrderedQuantity(
      data.line,
      data.yield?.actual ?? '0',
      data.scrap?.actual ?? '0',
      data.yield.standard,
    );
    this.oldWorkOrderId = data.workOrderId;
    this.oldWorkOrder = data.workOrderNumber;
    this.oldWorkOrderTableId = data.workOrderId;
    this.productNameOfTheWorkOrder = data.productDescription;
    this.finalQuantity = data.finalQuantity === null ? '0' : data.finalQuantity.toString();
    this.finalScrap = data.finalScrap === null ? '0' : data.finalScrap.toString();
    this.workOrderCloseTableData = [];

    const speedType: string = this.translate.instant(`main.batchClose.speedType.${data.speedType}`);
    this.speedInfo = `${speedType}: ${this.decimalHelper.toFixedValue(
      data.speed.toString(),
      this.siteDecimalScaleLimit$,
      true,
    )}`;

    this.workOrderActivityHistoryData = {
      ...this.workOrderActivityHistoryData,
      id: Number(data.workOrderId),
      completed: 0,
      canceled: false,
    };

    const camelCaseProductUnit: string = _.camelCase(data.productUnit);
    this.formattedProductUnit = this.translate.instant(`products.lookups.unit.${camelCaseProductUnit}.formula`);

    if (typeof data.oee !== 'undefined') {
      this.oeeProgress = {
        overall: +data.oee.overall,
        availability: +data.oee.a,
        performance: +data.oee.p,
        quality: +data.oee.q,
      };
    }

    if (typeof data.yield !== 'undefined') {
      this.workOrderCloseTableData.push(data.yield);
      this.workOrderCloseTableData[this.workOrderCloseTableData.length - 1].actualFinal = this.finalQuantity;
      this.workOrderCloseTableData[this.workOrderCloseTableData.length - 1].header = this.translate.instant(
        'main.batchClose.yield',
      );
      this.workOrderCloseSensorData.goodCount = data.yield.actual;
    }

    if (typeof data.scrap !== 'undefined') {
      this.workOrderCloseTableData.push(data.scrap);
      this.workOrderCloseTableData[this.workOrderCloseTableData.length - 1].actualFinal = this.finalScrap;
      this.workOrderCloseTableData[this.workOrderCloseTableData.length - 1].header = this.translate.instant(
        'main.batchClose.scrap',
      );
      this.workOrderCloseSensorData.scrapCount = data.scrap.actual;
    }

    if (typeof data.machineHour !== 'undefined') {
      const machineHourData: WorkOrderCloseStatisticsInterface = {
        ...data.machineHour,
        actual: data.machineHour.actual,
        actualFinal: data.machineHour.actualFinal,
      };

      this.workOrderCloseTableData.push(machineHourData);
      this.workOrderCloseTableData[this.workOrderCloseTableData.length - 1].header = this.translate.instant(
        'main.batchClose.machineHour',
      );
    }

    if (typeof data.laborHour !== 'undefined') {
      const laborHourData: WorkOrderCloseStatisticsInterface = {
        ...data.machineHour,
        actual: data.laborHour.actual,
        actualFinal: data.laborHour.actualFinal,
      };

      this.workOrderCloseTableData.push(laborHourData);
      this.workOrderCloseTableData[this.workOrderCloseTableData.length - 1].header = this.translate.instant(
        'main.batchClose.laborHour',
      );
    }

    if (typeof data.idleTime !== 'undefined') {
      this.workOrderCloseTableData.push(data.idleTime);
      this.workOrderCloseTableData[this.workOrderCloseTableData.length - 1].header = this.translate.instant(
        'main.batchClose.idleTime',
      );
    }

    if (typeof data.oeeBarStyles !== 'undefined') {
      this.oeeStyles = {
        overall: data.oeeBarStyles.overall.toString(),
        a: data.oeeBarStyles.a.toString(),
        p: data.oeeBarStyles.p.toString(),
        q: data.oeeBarStyles.q.toString(),
      };
    }
  }

  private setBatchModalData(): void {
    this.woFinalQuantityService
      .get(this.lineId$)
      .then((workOrderCloseResponse: WorkOrderCloseResponseInterface) => {
        this.store.dispatch(new AppActions.HideLoader());
        const formattedData: WorkOrderCloseDataInterface = BatchCloseComponent.setAdditionalFinalizeFormData(
          workOrderCloseResponse.data,
        );

        this.store
          .select('line')
          .pipe(take(1))
          .subscribe((lineState: Line) => {
            this.manualCountConfiguration = {
              workOrderId: Number(lineState.wOTableId),
              workOrder: lineState.workOrderId,
              start: lineState.activityStart,
              productId: lineState.productId,
              productDescription: lineState.productDescription,
              unitName: lineState.unitName,
            };
          });
        this.data = formattedData;

        this.openBatchCloseModal(this.data);

        this.store.dispatch(new AppActions.HideLoader());
      })
      .catch(() => {
        this.store.dispatch(new AppActions.HideLoader());
      });
  }

  submitWorkOrderCloseFeedbackForm(): void {
    this.store.dispatch(new AppActions.ShowLoader());
    this.workOrderFinalizeStoreSubscription = this.store
      .select('workOrderFinalize')
      .pipe(take(1))
      .subscribe((state: WorkOrderFinalizeStateInterface) => {
        this.changeActivityRequest = {
          ...state.selectedActivityAndTask,
          finalizeWorkOrder: true,
        };

        const feedBackPostData: WorkOrderCloseFeedbackInterface = {
          id: this.workOrderCloseFeedbackForm.id,
          workOrderId: this.workOrderCloseFeedbackForm.workOrderId,
          crewPerformPoint: this.workOrderCloseFeedbackForm.crewPerformPoint || null,
          goodThings: this.workOrderCloseFeedbackForm.goodThings || null,
          needToBeImprove: this.workOrderCloseFeedbackForm.needToBeImprove || null,
          variancesExplanation: this.workOrderCloseFeedbackForm.variancesExplanation || null,
        };

        if (!feedBackPostData.id) {
          this.store.dispatch(
            new HomeActions.SaveWorkOrderFeedback(...HelperService.argumentClone({
              ...feedBackPostData,
              workOrderId: this.oldWorkOrderTableId,
            })),
          );
        } else {
          this.store.dispatch(new HomeActions.UpdateWorkOrderFeedback(...HelperService.argumentClone(feedBackPostData)));
        }
      });
  }

  backButtonIsClicked(): void {
    this.modalClosed.emit();
  }

  public submitManageCountData(counts: IManageCountOutput): void {
    const isGoodLessThanZero = this.decimalHelper.isLessThan(counts.increments.goodCount, '0');
    const isScrapLessThanZero = this.decimalHelper.isLessThan(counts.increments.scrapCount, '0');

    this.store.dispatch(
      new ManualCountActions.ManualCountSetDistributionLoading(
        {
          workOrderId: this.data.workOrderId,
          goodCount: counts.totalCounts.goodCount,
          scrapCount: counts.totalCounts.scrapCount,
          doApproveOngoingShiftHour: true,
          ...(isScrapLessThanZero && isGoodLessThanZero
            ? {}
            : { incrementDistributionMethod: IncrementDistributionMethod.runTimesIfAvailable }),
        },
        true,
      ),
    );
  }

  ngOnDestroy(): void {
    if (this.workOrderFinalizeStoreSubscription) {
      this.workOrderFinalizeStoreSubscription.unsubscribe();
    }

    if (this.workOrderCloseFeedbackSubscription) {
      this.workOrderCloseFeedbackSubscription.unsubscribe();
    }

    if (this.homeStoreSubscription) {
      this.homeStoreSubscription.unsubscribe();
    }

    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  onSubmit(valid: boolean): void {
    if (!valid) {
      return;
    }

    this.store.dispatch(
      new ManualCountActions.ManualCountSetDistributionLoading({
        ...this.manualCountDistributionRequest,
        doApproveOngoingShiftHour: true,
      }),
    );
    this.quantityModalRef.close();
  }

  changeActivity(): void {
    this.store.dispatch(new AppActions.ShowLoader());
    this.store.dispatch(new HomeActions.ChangeActivity(...HelperService.argumentClone(this.changeActivityRequest)));
  }

  public static setAdditionalFinalizeFormData(
    responseData: WorkOrderCloseFormDataResponseInterface,
  ): WorkOrderCloseDataInterface {
    const formattedData: WorkOrderCloseFormDataResponseInterface = {
      ...responseData,
      yield: {
        ...responseData.yield,
        formatScale: 'site',
      },
      scrap: {
        ...responseData.scrap,
        formatScale: 'site',
      },
      laborHour: {
        ...responseData.laborHour,
        formatScale: 'user',
      },
      idleTime: {
        ...responseData.idleTime,
        formatScale: 'user',
      },
      machineHour: {
        ...responseData.machineHour,
        formatScale: 'user',
      },
      oeeBarStyles: {
        overall: OEEBarStyles.OeeNone,
        a: OEEBarStyles.OeeNone,
        p: OEEBarStyles.OeeNone,
        q: OEEBarStyles.OeeNone,
      },
    };

    const upperLimit = formattedData.upperLimit;

    if (upperLimit !== 0) {
      const lowerLimit = (upperLimit * 80) / 100;

      for (const formula of ['overall', 'a', 'p', 'q']) {
        const oeeFormula = formattedData.oee[formula];
        if (oeeFormula >= upperLimit) {
          formattedData.oeeBarStyles[formula] = OEEBarStyles.OeeSuccess;
        } else if (upperLimit > oeeFormula && oeeFormula >= lowerLimit) {
          formattedData.oeeBarStyles[formula] = OEEBarStyles.OeeNormal;
        } else if (lowerLimit > oeeFormula) {
          formattedData.oeeBarStyles[formula] = OEEBarStyles.OeeFail;
        }
      }
    }

    return formattedData;
  }

  public openManageCountModal(content: TemplateRef<any>): void {
    this.store.dispatch(new AppActions.ShowLoader());
    this.store.dispatch(new HomeActions.GetQuantityFromSensor(true));
    this.manageCountModalRef = this.ngbModal.open(content, mediumModal);
    this.manageCountModalRef.result.then((value) => {
      if (value) {
        this.store.dispatch(new AppActions.ShowLoader());
        this.store.dispatch(new HomeActions.GetQuantityFromSensor(true));
      }
    });
  }

  public openEditFinalQuantityModal(modalRef: TemplateRef<any>): void {
    this.manageCountModalRef?.close();
    this.finalQuantity =
      this.data.finalQuantity === null ? '0' : this.decimalHelper.formatBySeparator(this.data.finalQuantity, false);
    this.finalScrap =
      this.data.finalScrap === null ? '0' : this.decimalHelper.formatBySeparator(this.data.finalScrap, false);

    this.quantityModalRef = this.ngbModal.open(modalRef, {
      beforeDismiss: () =>
        showApplyChangesModal({
          dirty: this.finalCountDirty,
          modalService: this.ngbModal,
          apply: () => this.onSubmit(true),
        }),
      keyboard: false,
      backdrop: 'static',
      windowClass: 'scw-modal-xl scw-modal-all-scrollable',
    });
  }

  public setApprovedFinalCounts(manualCounts: IDistributionData): void {
    this.manualCountDistributionRequest = manualCounts;
  }

  public onClickWorkOrderActivityHistoryCloseButton(): void {
    this.store.dispatch(
      new HomeActions.WorkOrderMissingControl({
        workOrderId: this.workOrderActivityHistoryData.id,
        siteId: this.siteId$,
        lineId: this.lineId$,
      }),
    );
  }

  private checkAndSetDistributeOrderedQuantity(
    line: LineCRUDInterface,
    goodCount: string,
    scrapCount: string,
    quantityOrdered: string,
  ): void {
    this.distributeOrderedQuantityFlag = false;

    if (!line.distributeOrderedQuantity) {
      return;
    }

    switch (line.distributionMethodOfOrderedQuantity) {
      case EDistributionMethodOfOrderedQuantity.ORDERED_QUANTITY_AS_GOOD_COUNT_IF_ACTUAL_COUNT_ZERO:
        this.distributeOrderedQuantityFlag =
          this.decimalHelper.isEqual(goodCount, '0') && this.decimalHelper.isEqual(scrapCount, '0');
        this.distributeMethodTranslation = this.translate.instant(
          'main.workOrder.distributeOrderedQuantity.orderedQuantityAsGoodIfActualZero',
        );
        this.goodCountForDistribution = quantityOrdered;
        this.scrapCountForDistribution = '0';
        break;

      case EDistributionMethodOfOrderedQuantity.ORDERED_QUANTITY_AS_INITIAL_COUNT_OW_ACTUAL_COUNT:
        const initialCount: string = this.decimalHelper.add(goodCount, scrapCount);
        this.distributeOrderedQuantityFlag = !this.decimalHelper.isEqual(quantityOrdered, initialCount);
        this.distributeMethodTranslation = this.translate.instant(
          'main.workOrder.distributeOrderedQuantity.orderedQuantityAsInitialOwActual',
        );
        this.goodCountForDistribution = goodCount;
        this.scrapCountForDistribution = this.decimalHelper.subtract(quantityOrdered, goodCount);
        break;
      case EDistributionMethodOfOrderedQuantity.ORDERED_QUANTITY_AS_GOOD_COUNT_OW_ACTUAL_COUNT:
        const newInitialCountOw: string = this.decimalHelper.add(quantityOrdered, scrapCount);
        const isNewInitialCountGreaterThanZero: boolean = this.decimalHelper.isGreaterOrEqThan(newInitialCountOw, '0');
        this.distributeOrderedQuantityFlag =
          !this.decimalHelper.isEqual(quantityOrdered, goodCount) && isNewInitialCountGreaterThanZero;
        this.distributeMethodTranslation = this.translate.instant(
          'main.workOrder.distributeOrderedQuantity.orderedQuantityAsGoodOwActual',
        );
        this.goodCountForDistribution = quantityOrdered;
        this.scrapCountForDistribution = scrapCount;
        break;
      default:
        break;
    }
  }
}
